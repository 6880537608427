import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Toast } from "@nulogy/components";
import { config } from "../../playgrounds/toast";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Toast`}</h1>
    <p>{`Temporarily displayed messages that provide additional information or feedback about a user's action or event. Hovering over a message will keep it in view.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Use when`}</h2>
    <ul>
      <li parentName="ul">{`There are low priority messages to display in response to some event or action — i.e. messages that are helpful, although not intrinsic to the experience.`}</li>
      <li parentName="ul">{`The information contained is very concise and easy to read and understand within seconds.`}</li>
      <li parentName="ul">{`The message is less than 2 lines long or 150 characters.`}</li>
    </ul>
    <h2>{`Don't use when`}</h2>
    <ul>
      <li parentName="ul">{`The information contained is vital to completing a task.`}</li>
      <li parentName="ul">{`The message is too long to be read and understood within seconds (> 150 characters).`}</li>
    </ul>
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/alert"
        }}>{`Alert`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      